<template>
  <b-row>
    <b-col :sm="12">
      <h3>{{ $t("ITEMS") }}:</h3>
    </b-col>
    <b-col v-if="supplierName != ''" sm>
      <v-text-field
        :value="supplierName"
        :label="$t('SUPPLIER')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalSupplierDoc.num"
        :label="$t('DOC_NUM')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalSupplierDoc.date"
        :label="$t('DOC_DATE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalSupplierDoc.totalCurrency"
        :label="$t('TOTAL_PRICE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="selectedSupplierDocCurrencyCode"
        :label="$t('CURRENCY')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="selectedSupplierDocVatRate"
        :label="$t('VAT_GROUPS')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
  </b-row>
</template>
<script>
import _ from "lodash";
export default {
  name: "PreviewSupplierDocInfo",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    supplierName: {
      type: String,
      default: function() {
        return "";
      }
    },
    stockActionTypeId: {
      type: Number,
      default: 1
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    globalSupplierDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalSupplierDoc: {
      get: function() {
        return this.globalSupplierDoc;
      },
      set: function(newValue) {
        this.$emit("update:globalSupplierDoc", newValue);
      }
    },
    selectedSupplierDocCurrencyCode() {
      return this.selectedCurrencyCode(this.globalSupplierDoc.currencyId);
    },
    selectedSupplierDocVatRate() {
      return this.selectedVatRate(this.globalSupplierDoc.vatGroupId);
    }
  },
  watch: {},
  methods: {
    selectedCurrencyCode(currencyId) {
      const currencyConst = _.find(this.currencies, { id: currencyId });
      if (currencyConst) {
        return currencyConst.code;
      } else {
        return "";
      }
    },
    selectedVatRate(vatGroupId) {
      const vatGroupConst = _.find(this.vatGroups, { id: vatGroupId });
      if (vatGroupConst) {
        return vatGroupConst.name;
      } else {
        return "";
      }
    }
  }
};
</script>
