<template>
  <div>
    <b-row>
      <b-col>
        <StockInfoPanels v-if="!firstLoader" :storeName="selectedStore.name">
        </StockInfoPanels>
      </b-col>
    </b-row>
    <PreviewWholesaleDocInfo
      v-if="
        globalWholesaleDoc.visible &&
          (stockActionTypeId == 11 || stockActionTypeId == 13)
      "
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalWholesaleDoc="globalWholesaleDoc"
      :clientName="client.name"
      :clientCurrencyCode="client.currencyCode"
      :clientVatRate="client.vatRate"
      :clientStoreName="clientStore.name"
    />
    <PreviewReturningDocInfo
      v-if="globalReturningDoc.visible && stockActionTypeId == 3"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalReturningDoc="globalReturningDoc"
      :supplierName="supplier.name"
      :supplierCurrencyCode="supplier.currencyCode"
      :supplierVatRate="supplier.vatRate"
    />
    <PreviewSupplierDocInfo
      v-if="globalSupplierDoc.visible && stockActionTypeId == 1"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalSupplierDoc="globalSupplierDoc"
      :supplierName="selectedSupplier.name"
    />
    <PreviewTransportDocInfo
      v-if="globalTransportDoc.visible && stockActionTypeId == 1"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :forwarders="forwarders"
      :globalTransportDoc="globalTransportDoc"
      :selectedTransportDocForwarderName="selectedTransportDocForwarderName"
    />
    <PreviewAddonDocInfo
      v-if="globalAddonDoc.visible && stockActionTypeId == 1"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :contractors="contractors"
      :globalAddonDoc="globalAddonDoc"
      :selectedAddonDocContractorName="selectedAddonDocContractorName"
    />
    <PreviewPalletDocInfo
      v-if="globalPalletDoc.visible && stockActionTypeId == 1"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalPalletDoc="globalPalletDoc"
      :selectedPalletDocForwarderName="selectedPalletDocForwarderName"
    />
    <PreviewCustomsDocInfo
      v-if="globalCustomsDoc.visible && stockActionTypeId == 1"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :customs="customs"
      :globalCustomsDoc="globalCustomsDoc"
      :selectedCustomsDocCustomsName="selectedCustomsDocCustomsName"
    />
    <b-row>
      <b-col sm="12">
        <StockActionItemsPreviewTable
          :headers="headers"
          :items="selectedItems"
          :itemsTotal="selectedItemsTotal"
          :itemsTotalWholesale="selectedItemsTotalWholesale"
          :scrappingReasons="scrappingReasons"
          :scrappingTypes="scrappingTypes"
          :testingReasons="testingReasons"
          :showTotalPrice="showTotalPrice"
          :showTotalPriceWholesale="showTotalPriceWholesale"
        >
        </StockActionItemsPreviewTable>
      </b-col>
      <b-col sm="12">
        <v-textarea
          name="receiving-notes"
          :label="$t('NOTES')"
          auto-grow
          rows="1"
          prepend-icon="mdi-comment"
          v-model="tempNotes"
        ></v-textarea>
      </b-col>
      <b-col sm="3">
        <FormDatepickerBase
          :type="'text'"
          :clearable="false"
          :max="maxDate"
          :i18n="{
            label: 'FOR_DATE',
            placeholder: $t('FOR_DATE')
          }"
          :date.sync="forDate"
        ></FormDatepickerBase>
      </b-col>
    </b-row>
    <div class="mt-3">
      <b-button
        :disabled="false"
        class="mr-3"
        variant="primary"
        @click="onSave"
        >{{ $t("SAVE") }}</b-button
      >
      <b-button
        class="mr-3"
        variant="outline-primary"
        @click="updateWizardStep(2)"
        >{{ $t("BACK") }}</b-button
      >
    </div>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";
import StockActionItemsPreviewTable from "@/modules/stock/components/actions/StockActionItemsPreviewTable.vue";
import PreviewWholesaleDocInfo from "@/modules/stock/components/actions/PreviewWholesaleDocInfo.vue";
import PreviewReturningDocInfo from "@/modules/stock/components/actions/PreviewReturningDocInfo.vue";
import PreviewSupplierDocInfo from "@/modules/stock/components/actions/PreviewSupplierDocInfo.vue";
import PreviewTransportDocInfo from "@/modules/stock/components/actions/PreviewTransportDocInfo.vue";
import PreviewAddonDocInfo from "@/modules/stock/components/actions/PreviewAddonDocInfo.vue";
import PreviewPalletDocInfo from "@/modules/stock/components/actions/PreviewPalletDocInfo.vue";
import PreviewCustomsDocInfo from "@/modules/stock/components/actions/PreviewCustomsDocInfo.vue";

import {
  EXPORT_STOCK_REDIRECTING_XLS,
  SAVE_STOCK_ACTION
} from "@/modules/stock/store/stock.module";

import _ from "lodash";
import moment from "moment";

export default {
  name: "StepStockActionPreview",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    wizardStep: {
      type: Number,
      default: 1
    },
    stockActionTypeId: {
      type: Number,
      default: 1
    },
    client: {
      type: Object,
      default: function() {
        return {};
      }
    },
    supplier: {
      type: Object,
      default: function() {
        return {};
      }
    },
    clientStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    globalInternalDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          totalPrice: "0.00"
        };
      }
    },
    globalWholesaleDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 1,
          currencyId: 1,
          clientId: null,
          clientStoreId: null,
          totalCurrency: "0.00",
          totalCurrencyWholesale: "0.00"
        };
      }
    },
    globalReturningDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 1,
          currencyId: 1,
          supplierId: null,
          totalCurrency: "0.00",
          totalCurrencyReturning: "0.00"
        };
      }
    },
    globalSupplierDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalTransportDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalAddonDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalPalletDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalCustomsDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          customsId: 1,
          vatGroupId: 1,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    notes: {
      type: String,
      default: ""
    },
    selectedItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedItemsTotal: {
      type: Number,
      default: 0
    },
    selectedItemsTotalWholesale: {
      type: Number,
      default: 0
    },
    scrappingReasons: {
      type: Array,
      default: function() {
        return [];
      }
    },
    scrappingTypes: {
      type: Array,
      default: function() {
        return [];
      }
    },
    testingReasons: {
      type: Array,
      default: function() {
        return [];
      }
    },
    forwarders: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contractors: {
      type: Array,
      default: function() {
        return [];
      }
    },
    customs: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    selectedSupplier: {
      type: Object,
      default: function() {
        return {};
      }
    },
    actionReturning: {
      type: Boolean,
      default: true
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    },
    showTotalPriceWholesale: {
      type: Boolean,
      default: false
    },
    productionData: {
      type: Object,
      default: function() {
        return {
          isDough: false,
          mixer: null,
          tempRemainingMixerQuantity: 0
        };
      }
    }
  },
  data() {
    return {
      forDate: moment().format("YYYY-MM-DD")
    };
  },
  components: {
    FormDatepickerBase,
    StockInfoPanels,
    StockActionItemsPreviewTable,
    PreviewWholesaleDocInfo,
    PreviewReturningDocInfo,
    PreviewSupplierDocInfo,
    PreviewTransportDocInfo,
    PreviewAddonDocInfo,
    PreviewPalletDocInfo,
    PreviewCustomsDocInfo
  },
  beforeMount() {},
  mounted() {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempDoGetItems: {
      get: function() {
        return this.doGetItems;
      },
      set: function(newValue) {
        this.$emit("update:doGetItems", newValue);
      }
    },
    selectedSupplierDocCurrencyCode() {
      return this.selectedCurrencyCode(this.globalSupplierDoc.currencyId);
    },
    selectedSupplierDocVatRate() {
      return this.selectedVatRate(this.globalSupplierDoc.vatGroupId);
    },
    selectedTransportDocCurrencyCode() {
      if (this.globalTransportDoc.visible) {
        return this.selectedCurrencyCode(this.globalTransportDoc.currencyId);
      } else {
        return null;
      }
    },
    selectedTransportDocVatRate() {
      if (this.globalTransportDoc.visible) {
        return this.selectedVatRate(this.globalTransportDoc.vatGroupId);
      } else {
        return null;
      }
    },
    selectedTransportDocForwarderName() {
      if (this.globalTransportDoc.visible) {
        if (!this.globalTransportDoc.forwarderId) {
          return this.selectedSupplier.name;
        }
        return this.selectedForwarderName(this.globalTransportDoc.forwarderId);
      } else {
        return null;
      }
    },

    selectedAddonDocCurrencyCode() {
      if (this.globalAddonDoc.visible) {
        return this.selectedCurrencyCode(this.globalAddonDoc.currencyId);
      } else {
        return null;
      }
    },
    selectedAddonDocVatRate() {
      if (this.globalAddonDoc.visible) {
        return this.selectedVatRate(this.globalAddonDoc.vatGroupId);
      } else {
        return null;
      }
    },
    selectedAddonDocContractorName() {
      if (this.globalAddonDoc.visible) {
        if (!this.globalAddonDoc.contractorId) {
          return this.selectedSupplier.name;
        }
        return this.selectedContractorName(this.globalAddonDoc.contractorId);
      } else {
        return null;
      }
    },
    selectedCustomsDocCurrencyCode() {
      if (this.globalCustomsDoc.visible) {
        return this.selectedCurrencyCode(this.globalCustomsDoc.currencyId);
      } else {
        return null;
      }
    },
    selectedCustomsDocVatRate() {
      if (this.globalCustomsDoc.visible) {
        return this.selectedVatRate(this.globalCustomsDoc.vatGroupId);
      } else {
        return null;
      }
    },
    selectedCustomsDocCustomsName() {
      if (this.globalCustomsDoc.visible) {
        return this.selectedCustomsName(this.globalCustomsDoc.customsId);
      } else {
        return null;
      }
    },
    maxDate() {
      return moment().format("YYYY-MM-DD");
    },
    payload() {
      let vm = this;
      let payload = {};
      payload.stockActionTypeId = vm.stockActionTypeId;
      payload.storeId = vm.selectedStore.id;
      payload.note = vm.tempNotes;
      payload.forDate = vm.forDate;
      if (vm.globalInternalDoc.visible) {
        payload.internalDoc = vm.globalInternalDoc;
      }
      if (vm.globalWholesaleDoc.visible) {
        payload.wholesale = vm.globalWholesaleDoc;
      }
      if (vm.globalReturningDoc.visible) {
        payload.returning = vm.globalReturningDoc;
      }
      if (vm.globalSupplierDoc.visible) {
        payload.supplierPurchase = vm.globalSupplierDoc;
      }
      if (vm.globalTransportDoc.visible) {
        payload.transport = vm.globalTransportDoc;
      }
      if (vm.globalAddonDoc.visible) {
        payload.addon = vm.globalAddonDoc;
      }
      if (vm.globalPalletDoc.visible) {
        payload.pallet = vm.globalPalletDoc;
      }
      if (vm.globalCustomsDoc.visible) {
        payload.customs = vm.globalCustomsDoc;
      }

      // payload.docTotal = vm.selectedItemsTotal;
      if (vm.stockActionTypeId == 1 || vm.stockActionTypeId == 3) {
        //Receiving or Returning
        payload.supplierId = vm.selectedSupplier.id;
      }
      payload.items = vm.preparePayloadItems();
      return payload;
    }
  },
  watch: {},
  methods: {
    preparePayloadItems() {
      let vm = this;
      let items = [];
      vm.selectedItems.forEach(item => {
        let singleItem = {
          itemId: item.id,
          unitId: item.unitId,
          unitValue: item.stockActionItem.unitValue,
          itemPriceCurrency: item.stockActionItem.singlePrice,
          itemCustomsTotalFee: item.stockActionItem.customsTotalFee,
          lot: item.stockActionItem.lot,
          lotExpDate: item.stockActionItem.lotExpDate
        };

        if (vm.stockActionTypeId == 3) {
          //Returning
          singleItem.itemPriceCurrencyReturning = item.returning.singlePrice;
          singleItem.stockLotId = item.stockActionItem.stockLotId;
        }

        if (vm.stockActionTypeId == 5) {
          //SCRAPPING LOGICS
          singleItem.scrapping = {
            stockScrappingReasonId: item.scrapping.stockScrappingReasonId,
            stockScrappingTypeId: item.scrapping.stockScrappingTypeId
          };

          singleItem.stockLotId = item.stockActionItem.stockLotId;
        }

        if (vm.stockActionTypeId == 6) {
          //TESTING LOGICS
          singleItem.testing = {
            stockTestingReasonId: item.testing.stockTestingReasonId
          };
          singleItem.stockLotId = item.stockActionItem.stockLotId;
        }
        if (vm.stockActionTypeId == 11 || vm.stockActionTypeId == 13) {
          //Wholesale
          singleItem.itemPriceCurrencyWholesale = item.wholesale.singlePrice;
          singleItem.stockLotId = item.stockActionItem.stockLotId;
        }
        items = [...items, singleItem];
      });
      return items;
    },
    onSave() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          let payload = vm.payload;
          vm.$store
            .dispatch(SAVE_STOCK_ACTION, payload)
            .then(data => {
              vm.errors = data.data.errors;
              // alert(data.data.errors);
              // let itemEdit = data.data;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              // vm.$emit("update:selectedStore", null);
              vm.tempDoGetItems = true;
              vm.updateWizardStep(1);
              if ([1, 2, 4, 11].includes(vm.stockActionTypeId)) {
                vm.exportStockRedirectingXls(data.data.id);
              }
            })
            .catch(function(response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function() {});
    },
    selectedCurrencyCode(currencyId) {
      const currencyConst = _.find(this.currencies, { id: currencyId });
      if (currencyConst) {
        return currencyConst.code;
      } else {
        return "";
      }
    },
    selectedVatRate(vatGroupId) {
      const vatGroupConst = _.find(this.vatGroups, { id: vatGroupId });
      if (vatGroupConst) {
        return vatGroupConst.name;
      } else {
        return "";
      }
    },
    selectedForwarderName(forwarderId) {
      const forwarderConst = _.find(this.forwarders, { id: forwarderId });
      return forwarderConst.name;
    },
    selectedContractorName(contractorId) {
      const contractorConst = _.find(this.contractors, { id: contractorId });
      return contractorConst.name;
    },
    selectedCustomsName(customsId) {
      const customsConst = _.find(this.customs, { id: customsId });
      return customsConst.name;
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    },
    exportStockRedirectingXls(stockActionId) {
      let vm = this;
      let params = {
        stockActionId: stockActionId
      };
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(EXPORT_STOCK_REDIRECTING_XLS, apiParams)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "stock-receiving-note-" + stockActionId;
            link.click();
            link.remove();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    }
  }
};
</script>
