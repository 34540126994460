<template>
  <b-row>
    <b-col v-if="number != ''" sm>
      <v-text-field
        :value="number"
        :label="$t('NUMBER')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="storeName != ''" sm>
      <v-text-field
        :value="storeName"
        :label="$t('STORE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="fromStoreName != ''" sm>
      <v-text-field
        :value="fromStoreName"
        :label="$t('FROM_STORE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="toStoreName != ''" sm>
      <v-text-field
        :value="toStoreName"
        :label="$t('TO_STORE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="supplierName != '' && supplierName != null" sm>
      <v-text-field
        :value="supplierName"
        :label="$t('SUPPLIER')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="forDate != ''" sm>
      <v-text-field
        :value="forDate | formatDate"
        :label="$t('FOR_DATE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="userName != ''" sm>
      <v-text-field
        :value="userName"
        :label="$t('NAME')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="statusName != ''" sm>
      <v-text-field
        :value="statusName"
        :label="$t('STATUS')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="typeName != ''" sm>
      <v-text-field
        :value="typeName"
        :label="$t('TYPE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="refStoreName != ''" sm>
      <v-text-field
        :value="refStoreName"
        :label="$t('COUNTERPARTY')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
  </b-row>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  name: "StockInfoPanels",
  inheritAttrs: false,
  props: {
    number: {
      type: String,
      default: function() {
        return "";
      }
    },
    storeName: {
      type: String,
      default: function() {
        return "";
      }
    },
    fromStoreName: {
      type: String,
      default: function() {
        return "";
      }
    },
    toStoreName: {
      type: String,
      default: function() {
        return "";
      }
    },
    supplierName: {
      type: String,
      default: function() {
        return "";
      }
    },
    forDate: {
      type: String,
      default: function() {
        return "";
      }
    },
    userName: {
      type: String,
      default: function() {
        return "";
      }
    },
    statusName: {
      type: String,
      default: function() {
        return "";
      }
    },
    typeName: {
      type: String,
      default: function() {
        return "";
      }
    },
    refStoreName: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  data() {
    return {};
  },
  components: {},
  beforeMount() {},
  mounted() {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  watch: {},
  methods: {}
};
</script>
