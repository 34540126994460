<template>
  <b-row>
    <template v-if="tempGlobalTransportDoc.visible == false">
      <b-col>
        <v-btn color="blue darken-1" text @click="addService">
          <v-icon dark>mdi-plus</v-icon>
          {{ $t("ADD") }} {{ $t("TRANSPORT") }}
        </v-btn>
      </b-col>
    </template>
    <template v-if="tempGlobalTransportDoc.visible == true">
      <b-col :sm="12">
        <v-btn color="red darken-1" text @click="removeService">
          <v-icon dark>mdi-minus</v-icon>
          {{ $t("REMOVE") }}
          {{ $t("TRANSPORT") }}
        </v-btn>
        <h3>{{ $t("TRANSPORT") }}:</h3>
      </b-col>
      <b-col
        lg="3
      "
      >
        <v-autocomplete
          hide-details
          :items="forwarders"
          :label="$t('FORWARDER')"
          item-text="name"
          item-value="id"
          clearable
          v-model="tempGlobalTransportDoc.forwarderId"
        ></v-autocomplete>
      </b-col>
      <b-col>
        <v-text-field
          v-model="tempGlobalTransportDoc.num"
          :label="$t('DOC_NUM')"
          hide-details
          type="number"
          step="1"
          min="0"
        ></v-text-field>
      </b-col>
      <b-col>
        <FormDatepickerBase
          :type="'text'"
          :i18n="{
            label: 'DOC_DATE',
            placeholder: $t('DOC_DATE')
          }"
          :date.sync="tempGlobalTransportDoc.date"
        ></FormDatepickerBase>
      </b-col>
      <b-col>
        <v-text-field
          v-model="tempGlobalTransportDoc.totalCurrency"
          :label="$t('TOTAL_PRICE')"
          hide-details
          type="number"
          step="1"
          min="0"
        ></v-text-field>
      </b-col>
      <b-col lg="1">
        <v-select
          hide-details
          :items="currencies"
          item-text="code"
          item-value="id"
          :label="$t('CURRENCY')"
          v-model="tempGlobalTransportDoc.currencyId"
        ></v-select>
      </b-col>
      <b-col lg="1">
        <v-select
          hide-details
          :items="vatGroups"
          item-text="name"
          item-value="id"
          :label="$t('VAT_GROUPS')"
          v-model="tempGlobalTransportDoc.vatGroupId"
        ></v-select>
      </b-col>
    </template>
  </b-row>
</template>
<script>
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import _ from "lodash";
export default {
  name: "SetTransportDoc",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    forwarders: {
      type: Array,
      default: function() {
        return [];
      }
    },
    globalTransportDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    }
  },
  data() {
    return {};
  },
  components: { FormDatepickerBase },
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalTransportDoc: {
      get: function() {
        return this.globalTransportDoc;
      },
      set: function(newValue) {
        this.$emit("update:globalTransportDoc", newValue);
      }
    }
  },
  watch: {},
  methods: {
    addService() {
      let vm = this;
      vm.tempGlobalTransportDoc.visible = true;
    },
    removeService() {
      let vm = this;
      vm.tempGlobalTransportDoc.visible = false;
    }
  }
};
</script>
