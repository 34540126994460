<template>
  <div>
    <b-row>
      <b-col>
        <StockInfoPanels
          v-if="!firstLoader"
          :storeName="store.name"
          :supplierName="supplier.name"
        >
        </StockInfoPanels>
      </b-col>
    </b-row>

    <SetWholesaleDoc
      v-if="stockActionTypeId == 11 || stockActionTypeId == 13"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalWholesaleDoc="globalWholesaleDoc"
      :clientName="client.name"
      :clientCurrencyCode="client.currencyCode"
      :clientVatRate="client.vatRate"
      :clientStoreName="clientStore.name"
    />
    <SetReturningDoc
      v-if="stockActionTypeId == 3"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalReturningDoc="globalReturningDoc"
      :clientName="supplier.name"
      :supplierCurrencyCode="supplier.currencyCode"
      :supplierVatRate="supplier.vatRate"
    />
    <SetSupplierDoc
      v-if="stockActionTypeId == 1"
      :stockActionTypeId="stockActionTypeId"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalSupplierDoc="globalSupplierDoc"
      :supplierName="supplier.name"
    />
    <SetPalletDoc
      v-if="stockActionTypeId == 1"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalPalletDoc="globalPalletDoc"
      :supplierName="supplier.name"
    />
    <SetTransportDoc
      v-if="stockActionTypeId == 1"
      :forwarders="forwarders"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalTransportDoc="globalTransportDoc"
    />
    <SetAddonDoc
      v-if="stockActionTypeId == 1"
      :contractors="contractors"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :globalAddonDoc="globalAddonDoc"
    />
    <SetCustomsDoc
      v-if="stockActionTypeId == 1"
      :vatGroups="vatGroups"
      :currencies="currencies"
      :customs="customs"
      :globalCustomsDoc="globalCustomsDoc"
    />
    <v-divider></v-divider>
    <b-row v-if="stockActionTypeId == 5">
      <b-col lg="auto">
        <v-select
          hide-details
          :items="scrappingReasons"
          item-text="name"
          item-value="id"
          :label="$t('SCRAPPING_REASON')"
          v-model="tempGlobalScrapping.stockScrappingReasonId"
        ></v-select>
      </b-col>
      <b-col md="auto" lg>
        <v-select
          hide-details
          :items="scrappingTypes"
          item-text="name"
          item-value="id"
          :label="$t('SCRAPPING_TYPE')"
          v-model="tempGlobalScrapping.stockScrappingTypeId"
        ></v-select>
      </b-col>
      <b-col sm="auto" class="my-auto" v-if="applyButton">
        <b-button
          class="mr-3"
          variant="primary"
          @click="applyGlobalScrapping"
          >{{ $t("APPLY") }}</b-button
        >
      </b-col>
    </b-row>
    <v-divider v-if="stockActionTypeId == 5"></v-divider>
    <b-row v-if="stockActionTypeId == 6">
      <b-col>
        <v-select
          hide-details
          :items="testingReasons"
          item-text="name"
          item-value="id"
          :label="$t('TESTING_REASON')"
          v-model="tempGlobalTesting.stockTestingReasonId"
        ></v-select>
      </b-col>
      <b-col sm="auto" class="my-auto" v-if="applyButton">
        <b-button class="mr-3" variant="primary" @click="applyGlobalTesting">{{
          $t("APPLY")
        }}</b-button>
      </b-col>
    </b-row>
    <v-divider v-if="stockActionTypeId == 6"></v-divider>
    <b-row>
      <b-col :sm="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel
            v-for="(warehouse, index) in tempItems"
            v-bind:key="'warehouse-' + index"
            class="my-2"
          >
            <v-expansion-panel-header class="info text-white">
              <h4>
                {{ warehouse.name
                }}<small>({{ _.size(warehouse.items) }})</small>
              </h4>
              <template v-slot:actions>
                <v-icon color="white"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  clearable
                  append-icon="mdi-magnify"
                  :label="$t('SEARCH')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="warehouse.items"
                item-key="customIndex"
                :search="search"
                :items-per-page="20"
              >
                <template v-slot:item.name="{ item }">
                  <span
                    v-if="
                      stockActionTypeId == 1 ||
                        stockActionTypeId == 7 ||
                        stockActionTypeId == 13
                    "
                    ><v-icon @click="cloneItem(warehouse, item)"
                      >mdi-plus</v-icon
                    ></span
                  >
                  {{ item.name }}<br />
                  <p>
                    <small
                      >(1 {{ item.packageName }} = {{ item.unitValue
                      }}<span v-if="item.id == 1">бр.</span>
                      <span v-else>{{ item.unitShortName }}</span
                      >)</small
                    >
                  </p>
                </template>
                <template
                  v-slot:item.stockActionItem.requestedUnitValue="{ item }"
                >
                  ({{
                    item.stockActionItem.requestedUnitValue / item.unitValue
                  }}
                  {{ item.packageName }})<br />
                  {{ item.stockActionItem.requestedUnitValue }}
                  {{ item.unitShortName }}
                </template>
                <template
                  v-slot:item.stockActionItem.currentUnitValue="{ item }"
                >
                  ({{ item.stockActionItem.currentUnitValue / item.unitValue }}
                  {{ item.packageName }})<br />
                  {{ item.stockActionItem.currentUnitValue }}
                  {{ item.unitShortName }}
                </template>
                <template v-slot:item.stockActionItem.packageValue="{ item }">
                  <v-text-field
                    class="my-5"
                    v-model="item.stockActionItem.packageValue"
                    hide-details
                    :disabled="item.id == 1"
                    type="number"
                    :step="0.01"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterPackageValue(item)"
                  >
                    <template v-slot:append>
                      ({{ item.packageName }})
                    </template>
                  </v-text-field>
                </template>
                <template v-slot:item.stockActionItem.unitValue="{ item }">
                  <v-text-field
                    class="my-5"
                    v-model="item.stockActionItem.unitValue"
                    hide-details
                    type="number"
                    :step="item.unitNumberType == 2 ? 1 : 0.001"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterUnitValue(item)"
                  >
                    <template v-slot:append>
                      <span v-if="item.id == 1">(бр.)</span>
                      <span v-else> ({{ item.unitShortName }}) </span>
                    </template>
                  </v-text-field>
                </template>
                <template v-slot:item.stockActionItem.singlePrice="{ item }">
                  <v-text-field
                    v-if="
                      stockActionTypeId != 3 &&
                        stockActionTypeId != 11 &&
                        stockActionTypeId != 13
                    "
                    v-model="item.stockActionItem.singlePrice"
                    :label="$t('SINGLE_PRICE')"
                    hide-details
                    type="number"
                    step="0.000001"
                    min="0"
                    hide-spin-buttons
                    @input="handleInput"
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterSinglePrice(item)"
                  ></v-text-field>
                  <template
                    v-if="
                      stockActionTypeId == 3 ||
                        stockActionTypeId == 11 ||
                        stockActionTypeId == 13
                    "
                    >{{ item.stockActionItem.singlePrice }}</template
                  >
                </template>
                <template v-slot:item.stockActionItem.totalPrice="{ item }">
                  <v-text-field
                    v-if="
                      stockActionTypeId != 3 &&
                        stockActionTypeId != 11 &&
                        stockActionTypeId != 13
                    "
                    v-model="item.stockActionItem.totalPrice"
                    :label="$t('TOTAL_PRICE')"
                    hide-details
                    type="number"
                    step="0.01"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterTotalPrice(item)"
                  ></v-text-field>
                  <template
                    v-if="
                      stockActionTypeId == 3 ||
                        stockActionTypeId == 11 ||
                        stockActionTypeId == 13
                    "
                    >{{ item.stockActionItem.totalPrice }}</template
                  >
                </template>
                <template v-slot:item.wholesale.singlePrice="{ item }">
                  <v-text-field
                    v-if="stockActionTypeId == 11 || stockActionTypeId == 13"
                    v-model="item.wholesale.singlePrice"
                    :label="$t('SINGLE_PRICE_WHOLESALE')"
                    hide-details
                    type="number"
                    step="0.01"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterSinglePriceWholesale(item)"
                  ></v-text-field>
                  <template
                    v-if="stockActionTypeId != 11 && stockActionTypeId != 13"
                    >{{ item.wholesale.singlePrice }}</template
                  >
                </template>
                <template v-slot:item.wholesale.totalPrice="{ item }">
                  <v-text-field
                    v-if="stockActionTypeId == 11 || stockActionTypeId == 13"
                    v-model="item.wholesale.totalPrice"
                    :label="$t('TOTAL_PRICE_WHOLESALE')"
                    hide-details
                    type="number"
                    step="0.01"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterTotalPriceWholesale(item)"
                  ></v-text-field>
                  <template
                    v-if="stockActionTypeId != 11 && stockActionTypeId != 13"
                    >{{ item.wholesale.totalPrice }}</template
                  >
                </template>
                <template v-slot:item.returning.singlePrice="{ item }">
                  <v-text-field
                    v-model="item.returning.singlePrice"
                    :label="$t('SINGLE_PRICE_RETURNING')"
                    hide-details
                    type="number"
                    step="0.01"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterSinglePriceReturning(item)"
                  ></v-text-field>
                </template>
                <template v-slot:item.returning.totalPrice="{ item }">
                  <v-text-field
                    v-model="item.returning.totalPrice"
                    :label="$t('TOTAL_PRICE_RETURNING')"
                    hide-details
                    type="number"
                    step="0.01"
                    min="0"
                    hide-spin-buttons
                    @keydown.native="preventArrowKeys"
                    @focus="$event.target.select()"
                    @blur="filterTotalPriceReturning(item)"
                  ></v-text-field>
                </template>
                <template
                  v-slot:item.stockActionItem.customsTotalFee="{ item }"
                >
                  <v-text-field
                    v-if="!actionReturning"
                    v-model="item.stockActionItem.customsTotalFee"
                    :label="$t('CUSTOMS_PRICE')"
                    hide-details
                    type="number"
                    step="0.01"
                    min="0"
                  ></v-text-field>
                  <span v-else>{{ item.stockActionItem.customsTotalFee }}</span>
                </template>
                <template v-slot:item.stockActionItem.lot="{ item }">
                  <v-text-field
                    v-model="item.stockActionItem.lot"
                    :label="$t('LOT')"
                    hide-details
                    type="text"
                  ></v-text-field>
                </template>
                <template v-slot:item.stockActionItem.lotExpDate="{ item }">
                  <FormDatepickerBase
                    :type="'text'"
                    :i18n="{
                      label: 'LOT_EXP_DATE',
                      placeholder: $t('LOT_EXP_DATE')
                    }"
                    :date.sync="item.stockActionItem.lotExpDate"
                  ></FormDatepickerBase>
                </template>
                <template v-slot:item.stockActionItem.stockLot="{ item }">
                  {{ item.stockActionItem.stockLot }}
                </template>
                <template
                  v-slot:item.stockActionItem.stockLotExpDate="{ item }"
                >
                  {{ item.stockActionItem.stockLotExpDate | formatDate }}
                </template>
                <template
                  v-slot:item.stockActionItem.sumLastStockActionsUnitValue="{
                    item
                  }"
                >
                  {{ item.stockActionItem.sumLastStockActionsUnitValue }} ({{
                    item.unitShortName
                  }})
                </template>

                <template
                  v-slot:item.scrapping.stockScrappingReasonId="{ item }"
                >
                  <v-select
                    hide-details
                    :items="scrappingReasons"
                    item-text="name"
                    item-value="id"
                    :label="$t('SCRAPPING_REASON')"
                    v-model="item.scrapping.stockScrappingReasonId"
                  ></v-select>
                </template>
                <template v-slot:item.scrapping.stockScrappingTypeId="{ item }">
                  <v-select
                    hide-details
                    :items="scrappingTypes"
                    item-text="name"
                    item-value="id"
                    :label="$t('SCRAPPING_TYPE')"
                    v-model="item.scrapping.stockScrappingTypeId"
                  ></v-select>
                </template>
                <template v-slot:item.testing.stockTestingReasonId="{ item }">
                  <v-select
                    hide-details
                    :items="testingReasons"
                    item-text="name"
                    item-value="id"
                    :label="$t('TESTING_REASON')"
                    v-model="item.testing.stockTestingReasonId"
                  ></v-select>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </b-col>
      <b-col>
        <v-textarea
          name="stock-action-notes"
          :label="$t('NOTES')"
          auto-grow
          rows="1"
          prepend-icon="mdi-comment"
          v-model="tempNotes"
        ></v-textarea>
      </b-col>
    </b-row>

    <template v-if="showTotalPrice">
      <div>
        {{ $t("TOTAL_PRICE") }}:
        {{ selectedItemsTotal }}
      </div>
    </template>
    <template v-if="showTotalPriceWholesale">
      <div>
        {{ $t("TOTAL_PRICE_WHOLESALE") }}:
        {{ selectedItemsTotalWholesale }}
      </div>
    </template>
    <b-button
      :disabled="
        stockActionTypeId == 7 &&
          productionData.isDough == true &&
          (productionData.mixer.mixerQuantity == 0 ||
            productionData.tempRemainingMixerQuantity > 5 ||
            productionData.tempRemainingMixerQuantity < -5)
      "
      class="mr-3"
      variant="primary"
      @click="updateWizardStep(3)"
      >{{ $t("CONTINUE") }}</b-button
    >
    <b-button
      class="mr-3"
      variant="outline-primary"
      @click="updateWizardStep(1)"
      >{{ $t("BACK") }}</b-button
    >
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";
import SetWholesaleDoc from "@/modules/stock/components/actions/stockReceiving/SetWholesaleDoc.vue";
import SetReturningDoc from "@/modules/stock/components/actions/stockReceiving/SetReturningDoc.vue";
import SetSupplierDoc from "@/modules/stock/components/actions/stockReceiving/SetSupplierDoc.vue";
import SetTransportDoc from "@/modules/stock/components/actions/stockReceiving/SetTransportDoc.vue";
import SetAddonDoc from "@/modules/stock/components/actions/stockReceiving/SetAddonDoc.vue";
import SetPalletDoc from "@/modules/stock/components/actions/stockReceiving/SetPalletDoc.vue";
import SetCustomsDoc from "@/modules/stock/components/actions/stockReceiving/SetCustomsDoc.vue";

import _ from "lodash";
export default {
  name: "StepStockActionSetItems",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    wizardStep: {
      type: Number,
      default: 1
    },
    stockActionTypeId: {
      type: Number,
      default: 1
    },
    globalInternalDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          totalPrice: "0.00"
        };
      }
    },
    globalWholesaleDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 1,
          currencyId: 1,
          clientId: null,
          clientStoreId: null,
          totalCurrency: "0.00",
          totalCurrencyWholesale: "0.00"
        };
      }
    },
    globalReturningDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          supplierId: null,
          totalCurrency: "0.00",
          totalCurrencyReturning: "0.00"
        };
      }
    },
    globalSupplierDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalTransportDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalAddonDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalPalletDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    globalCustomsDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          customsId: 1,
          vatGroupId: 3,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    },
    applyButton: {
      type: Boolean,
      default: true
    },
    notes: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    headers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedItems: {
      type: Array,
      default: function() {
        return [];
      }
    },
    selectedItemsTotal: {
      type: Number,
      default: 0
    },
    selectedItemsTotalWholesale: {
      type: Number,
      default: 0
    },
    globalScrapping: {
      type: Object,
      default: function() {
        return {};
      }
    },
    globalTesting: {
      type: Object,
      default: function() {
        return {};
      }
    },
    scrappingReasons: {
      type: Array,
      default: function() {
        return [];
      }
    },
    scrappingTypes: {
      type: Array,
      default: function() {
        return [];
      }
    },
    testingReasons: {
      type: Array,
      default: function() {
        return [];
      }
    },
    forwarders: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contractors: {
      type: Array,
      default: function() {
        return [];
      }
    },
    customs: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    store: {
      type: Object,
      default: function() {
        return {};
      }
    },
    client: {
      type: Object,
      default: function() {
        return {};
      }
    },
    clientStore: {
      type: Object,
      default: function() {
        return {};
      }
    },
    supplier: {
      type: Object,
      default: function() {
        return {};
      }
    },
    actionReturning: {
      type: Boolean,
      default: false
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    },
    showTotalPriceWholesale: {
      type: Boolean,
      default: false
    },
    productionData: {
      type: Object,
      default: function() {
        return {
          isDough: false,
          mixer: null,
          tempRemainingMixerQuantity: 0
        };
      }
    }
  },
  data() {
    return {
      panel: 0,
      search: ""
    };
  },
  components: {
    FormDatepickerBase,
    StockInfoPanels,
    SetWholesaleDoc,
    SetReturningDoc,
    SetSupplierDoc,
    SetTransportDoc,
    SetAddonDoc,
    SetPalletDoc,
    SetCustomsDoc
  },
  beforeMount() {},
  mounted() {},
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalInternalDoc: {
      get: function() {
        return this.globalInternalDoc;
      },
      set: function(newValue) {
        this.$emit("update:globalInternalDoc", newValue);
      }
    },
    tempGlobalScrapping: {
      get: function() {
        return this.globalScrapping;
      },
      set: function(newValue) {
        this.$emit("update:globalScrapping", newValue);
      }
    },
    tempGlobalTesting: {
      get: function() {
        return this.globalTesting;
      },
      set: function(newValue) {
        this.$emit("update:globalTesting", newValue);
      }
    },
    tempNotes: {
      get: function() {
        return this.notes;
      },
      set: function(newValue) {
        this.$emit("update:notes", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    }
  },
  watch: {},
  methods: {
    preventArrowKeys(event) {
      // Check if the pressed key is an arrow key
      if (["ArrowUp", "ArrowDown"].includes(event.key)) {
        event.preventDefault();
      }
      if (event.key === "," && !event.target.value.includes(".")) {
        event.preventDefault();
        event.target.setAttribute("type", "text");
        event.target.value = event.target.value + ".0";
        event.target.setSelectionRange(
          event.target.value.length - 1,
          event.target.value.length
        );
        event.target.setAttribute("type", "number");
      }
    },
    applyGlobalScrapping() {
      let vm = this;
      vm.tempItems.forEach(function(part, index) {
        vm.tempItems[index].items.forEach(function(itemPart, itemIndex) {
          vm.$set(
            vm.tempItems[index].items[itemIndex].scrapping,
            "stockScrappingReasonId",
            vm.globalScrapping.stockScrappingReasonId
          );

          vm.$set(
            vm.tempItems[index].items[itemIndex].scrapping,
            "stockScrappingTypeId",
            vm.globalScrapping.stockScrappingTypeId
          );
        }, vm.tempItems); // use arr as this
      }, vm.tempItems); // use arr as this
    },
    applyGlobalTesting() {
      let vm = this;
      vm.tempItems.forEach(function(part, index) {
        vm.tempItems[index].items.forEach(function(itemPart, itemIndex) {
          vm.$set(
            vm.tempItems[index].items[itemIndex].testing,
            "stockTestingReasonId",
            vm.globalTesting.stockTestingReasonId
          );
        }, vm.tempItems); // use arr as this
      }, vm.tempItems); // use arr as this
    },
    filterUnitValue(item) {
      //Това тук вкл и lastAudit remaining stock (нищо, че пише sumLastStockActions...). В самия сървис след взимането на движенията се добавя и стоката от remaining по лотове...
      let hasSumLastStockActionsUnitValue = _.has(
        item,
        "stockActionItem.sumLastStockActionsUnitValue"
      );
      if (
        hasSumLastStockActionsUnitValue &&
        parseFloat(item.stockActionItem.sumLastStockActionsUnitValue) <
          parseFloat(item.stockActionItem.unitValue)
      ) {
        //Ако има максимална бройка, проверяваме дали я надвишаваме и ако да, слагаме стойността да е равна на максимума
        item.stockActionItem.unitValue =
          item.stockActionItem.sumLastStockActionsUnitValue;
      }
      item.stockActionItem.unitValue = this.formatItemField(
        item,
        Math.abs(item.stockActionItem.unitValue)
      );
      item.stockActionItem.packageValue = Math.abs(
        item.stockActionItem.unitValue / item.unitValue
      );
      this.updateItemTotalPrice(item);
      this.updateItemTotalPriceWholesale(item);
      this.updateItemTotalPriceReturning(item);
    },
    filterPackageValue(item) {
      item.stockActionItem.packageValue = Math.abs(
        item.stockActionItem.packageValue
      );
      item.stockActionItem.unitValue = this.formatItemField(
        item,
        Math.abs(item.stockActionItem.packageValue * item.unitValue)
      );
      this.updateItemTotalPrice(item);
    },

    filterSinglePrice(item) {
      item.stockActionItem.singlePrice = this.$options.filters.formatDecimal6(
        item.stockActionItem.singlePrice
      );
      this.updateItemTotalPrice(item);
    },
    filterTotalPrice(item) {
      item.stockActionItem.totalPrice = this.$options.filters.formatDecimal6(
        item.stockActionItem.totalPrice
      );
      this.updateItemSinglePrice(item);
    },
    updateItemTotalPrice(item) {
      let value =
        parseFloat(item.stockActionItem.unitValue) *
        parseFloat(item.stockActionItem.singlePrice);
      item.stockActionItem.totalPrice = this.$options.filters.formatDecimal6(
        value
      );
    },
    updateItemSinglePrice(item) {
      let value =
        parseFloat(item.stockActionItem.totalPrice) /
        parseFloat(item.stockActionItem.unitValue);
      item.stockActionItem.singlePrice = this.$options.filters.formatDecimal6(
        value
      );
    },

    filterSinglePriceWholesale(item) {
      item.wholesale.singlePrice = this.$options.filters.formatDecimal6(
        item.wholesale.singlePrice
      );
      this.updateItemTotalPriceWholesale(item);
    },
    filterTotalPriceWholesale(item) {
      item.wholesale.totalPrice = this.$options.filters.formatBalance(
        item.wholesale.totalPrice
      );
      this.updateItemSinglePriceWholesale(item);
    },
    updateItemTotalPriceWholesale(item) {
      if (!item.wholesale) return;
      let value =
        parseFloat(item.stockActionItem.unitValue) *
        parseFloat(item.wholesale.singlePrice);
      item.wholesale.totalPrice = this.$options.filters.formatDecimal6(value);
    },
    updateItemSinglePriceWholesale(item) {
      if (!item.wholesale) return;
      let value =
        parseFloat(item.wholesale.totalPrice) /
        parseFloat(item.stockActionItem.unitValue);
      item.wholesale.singlePrice = this.$options.filters.formatBalance(value);
    },

    filterSinglePriceReturning(item) {
      item.returning.singlePrice = this.$options.filters.formatDecimal6(
        item.returning.singlePrice
      );
      this.updateItemTotalPriceReturning(item);
    },
    filterTotalPriceReturning(item) {
      item.returning.totalPrice = this.$options.filters.formatBalance(
        item.returning.totalPrice
      );
      this.updateItemSinglePriceReturning(item);
    },
    updateItemTotalPriceReturning(item) {
      if (!item.returning) return;
      let value =
        parseFloat(item.stockActionItem.unitValue) *
        parseFloat(item.returning.singlePrice);
      item.returning.totalPrice = this.$options.filters.formatBalance(value);
    },
    updateItemSinglePriceReturning(item) {
      if (!item.returning) return;
      let value =
        parseFloat(item.returning.totalPrice) /
        parseFloat(item.stockActionItem.unitValue);
      item.returning.singlePrice = this.$options.filters.formatDecimal6(value);
    },

    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    formatItemField(item, value) {
      if (item.unitNumberType == 2) {
        value = Math.round(value);
      } else {
        value = this.$options.filters.formatUnitValue(value);
      }
      return value;
    },
    checkIfContinueIsDisabled() {
      let vm = this;
      if (_.size(vm.selectedItems) == 0) {
        return true;
      }
      if (
        vm.tempGlobalInternalDoc.num == "" ||
        vm.tempGlobalInternalDoc.date == ""
      ) {
        return true;
      }
      // if (
      //   vm.globalTransportDoc.visible == true &&
      //   (vm.globalTransportDoc.num == "" || vm.globalTransportDoc.date == "")
      // ) {
      //   return true;
      // }
      // if (
      //   vm.globalCustomsDoc.visible == true &&
      //   (vm.globalCustomsDoc.num == "" || vm.globalCustomsDoc.date == "")
      // ) {
      //   return true;
      // }
    },
    cloneItem(warehouse, item) {
      // Make a deep copy of the item to be cloned
      const clonedItem = JSON.parse(JSON.stringify(item));
      const itemIndex = warehouse.items.indexOf(item);
      warehouse.items = warehouse.items
        .slice(0, itemIndex)
        .concat(clonedItem, warehouse.items.slice(itemIndex));
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
