<template>
  <b-row>
    <b-col :sm="12">
      <h3>{{ $t("CUSTOMS") }}:</h3>
    </b-col>
    <b-col>
      <v-text-field
        :value="selectedCustomsDocCustomsName"
        :label="$t('CUSTOMS_OFFICE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalCustomsDoc.num"
        :label="$t('DOC_NUM')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalCustomsDoc.date"
        :label="$t('DOC_DATE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalCustomsDoc.totalCurrency"
        :label="$t('TOTAL_PRICE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="selectedCustomsDocCurrencyCode"
        :label="$t('CURRENCY')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="selectedCustomsDocVatRate"
        :label="$t('VAT_GROUPS')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
  </b-row>
</template>
<script>
import _ from "lodash";
export default {
  name: "PreviewCustomsDocInfo",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    selectedCustomsDocCustomsName: {
      type: String,
      default: ""
    },
    stockActionTypeId: {
      type: Number,
      default: 1
    },
    customs: {
      type: Array,
      default: function () {
        return [];
      }
    },
    vatGroups: {
      type: Array,
      default: function () {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function () {
        return [];
      }
    },
    globalCustomsDoc: {
      type: Object,
      default: function () {
        return {
          visible: false,
          num: "",
          date: "",
          customsId: 1,
          vatGroupId: 1,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function () {
        return this.firstLoader;
      },
      set: function (newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    selectedCustomsDocCurrencyCode() {
      return this.selectedCurrencyCode(this.globalCustomsDoc.currencyId);
    },
    selectedCustomsDocVatRate() {
      return this.selectedVatRate(this.globalCustomsDoc.vatGroupId);
    }
  },
  watch: {},
  methods: {
    selectedCurrencyCode(currencyId) {
      const currencyConst = _.find(this.currencies, { id: currencyId });
      if (currencyConst) {
        return currencyConst.code;
      } else {
        return "";
      }
    },
    selectedVatRate(vatGroupId) {
      const vatGroupConst = _.find(this.vatGroups, { id: vatGroupId });
      if (vatGroupConst) {
        return vatGroupConst.name;
      } else {
        return "";
      }
    },
    selectedCustomsName(customsId) {
      const customsConst = _.find(this.customs, { id: customsId });
      return customsConst.name;
    }
  }
};
</script>
