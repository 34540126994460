<template>
  <v-menu
    v-model="modal"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :disabled="disabled"
        :clearable="clearable"
        v-model="tempDate"
        :label="$t(i18n.label)"
        prepend-icon="mdi-calendar"
        hide-details
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="tempDate"
      @input="submit"
      :first-day-of-week="1"
      :locale="activeLanguage"
      :max="max"
      no-title
    >
    </v-date-picker>
  </v-menu>
</template>
<script>
import i18nService from "@/core/services/i18n.service.js";
export default {
  name: "FormDatepickerBase",
  inheritAttrs: false,
  props: {
    date: String,
    /**
     * All Translation i18n keys used
     */
    i18n: Object,
    /**
     * Is clearable date or not
     */
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      modal: false
    };
  },
  components: {},
  beforeMount() {
    // this.initializeDate();
  },
  mounted() {},
  computed: {
    activeLanguage() {
      return i18nService.getActiveLanguage();
    },
    tempDate: {
      get: function() {
        return this.date;
      },
      set: function(newValue) {
        this.$emit("update:date", newValue);
      }
    }
  },
  methods: {
    submit(closePicker = true) {
      // let selectedDate = this.date;
      if (closePicker) {
        this.modal = false;
      }
    }
  }
};
</script>
