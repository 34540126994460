<template>
  <b-row>
    <b-col :sm="12">
      <h3>{{ $t("ITEMS") }}:</h3>
    </b-col>
    <b-col v-if="clientName != ''" sm>
      <v-text-field
        :value="clientName"
        :label="$t('CLIENT')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col v-if="clientStoreName != ''" sm>
      <v-text-field
        :value="clientStoreName"
        :label="$t('CLIENT_STORE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalWholesaleDoc.num"
        :label="$t('DOC_NUM')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalWholesaleDoc.date"
        :label="$t('DOC_DATE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalWholesaleDoc.totalCurrencyWholesale"
        :label="$t('TOTAL_PRICE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="clientCurrencyCode"
        :label="$t('CURRENCY')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="clientVatRate"
        :label="$t('VAT_RATE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
  </b-row>
</template>
<script>
import _ from "lodash";
export default {
  name: "PreviewWholesaleDocInfo",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    clientName: {
      type: String,
      default: function() {
        return "";
      }
    },
    clientCurrencyCode: {
      type: String,
      default: function() {
        return "";
      }
    },
    clientVatRate: {
      type: String,
      default: function() {
        return "0";
      }
    },
    clientStoreName: {
      type: String,
      default: function() {
        return "";
      }
    },
    stockActionTypeId: {
      type: Number,
      default: 1
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    globalWholesaleDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 1,
          currencyId: 1,
          clientId: null,
          clientStoreId: null,
          totalCurrency: "0.00",
          totalCurrencyWholesale: "0.00"
        };
      }
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalWholesaleDoc: {
      get: function() {
        return this.globalWholesaleDoc;
      },
      set: function(newValue) {
        this.$emit("update:globalWholesaleDoc", newValue);
      }
    }
  },
  watch: {},
  methods: {}
};
</script>
