<template>
  <b-row>
    <b-col :sm="12">
      <h3>{{ $t("ITEMS") }}:</h3>
    </b-col>
    <b-col v-if="supplierName != ''" sm>
      <v-text-field
        :value="supplierName"
        :label="$t('SUPPLIER')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalReturningDoc.num"
        :label="$t('DOC_NUM')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalReturningDoc.date"
        :label="$t('DOC_DATE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="globalReturningDoc.totalCurrencyReturning"
        :label="$t('TOTAL_PRICE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="supplierCurrencyCode"
        :label="$t('CURRENCY')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        :value="supplierVatRate"
        :label="$t('VAT_RATE')"
        outlined
        disabled
        readonly
      ></v-text-field>
    </b-col>
  </b-row>
</template>
<script>
import _ from "lodash";
export default {
  name: "PreviewReturningDocInfo",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    supplierName: {
      type: String,
      default: function() {
        return "";
      }
    },
    supplierCurrencyCode: {
      type: String,
      default: function() {
        return "";
      }
    },
    supplierVatRate: {
      type: String,
      default: function() {
        return "0";
      }
    },
    supplierStoreName: {
      type: String,
      default: function() {
        return "";
      }
    },
    stockActionTypeId: {
      type: Number,
      default: 1
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    globalReturningDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          supplierId: null,
          totalCurrency: "0.00",
          totalCurrencyReturning: "0.00"
        };
      }
    }
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalReturningDoc: {
      get: function() {
        return this.globalReturningDoc;
      },
      set: function(newValue) {
        this.$emit("update:globalReturningDoc", newValue);
      }
    }
  },
  watch: {},
  methods: {}
};
</script>
