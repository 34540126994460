<template>
  <b-row>
    <b-col :sm="12">
      <h3>{{ $t("ITEMS") }}:</h3>
    </b-col>
    <b-col lg="2">
      <v-text-field
        :disabled="true"
        v-model="supplierName"
        :label="$t('SUPPLIER')"
        hide-details
      ></v-text-field>
    </b-col>
    <b-col lg="2">
      <v-text-field
        :disabled="true"
        v-model="supplierStoreName"
        :label="$t('SUPPLIER_STORE')"
        hide-details
      ></v-text-field>
    </b-col>
    <b-col>
      <v-text-field
        v-model="tempGlobalReturningDoc.num"
        :label="$t('DOC_NUM')"
        hide-details
        type="number"
        step="1"
        min="0"
      ></v-text-field>
    </b-col>
    <b-col>
      <FormDatepickerBase
        :type="'text'"
        :i18n="{
          label: 'DOC_DATE',
          placeholder: $t('DOC_DATE')
        }"
        :date.sync="tempGlobalReturningDoc.date"
      ></FormDatepickerBase>
    </b-col>
    <b-col>
      <v-text-field
        :disabled="true"
        v-model="tempGlobalReturningDoc.totalCurrencyReturning"
        :label="$t('TOTAL_PRICE')"
        hide-details
        type="number"
        step="1"
        min="0"
      ></v-text-field>
    </b-col>
    <b-col lg="1">
      <v-text-field
        :disabled="true"
        v-model="supplierCurrencyCode"
        :label="$t('CURRENCY')"
        hide-details
      ></v-text-field>
    </b-col>
    <b-col lg="1">
      <v-text-field
        :disabled="true"
        v-model="supplierVatRate"
        :label="$t('VAT_RATE')"
        hide-details
      ></v-text-field>
    </b-col>
  </b-row>
</template>
<script>
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import _ from "lodash";
export default {
  name: "SetReturningDoc",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    supplierName: {
      type: String,
      default: function() {
        return "";
      }
    },
    supplierCurrencyCode: {
      type: String,
      default: function() {
        return "";
      }
    },
    supplierVatRate: {
      type: String,
      default: function() {
        return "0";
      }
    },
    supplierStoreName: {
      type: String,
      default: function() {
        return "";
      }
    },
    stockActionTypeId: {
      type: Number,
      default: 1
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    globalReturningDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          vatGroupId: 3,
          currencyId: 1,
          supplierId: null,
          totalCurrency: "0.00",
          totalCurrencyReturning: "0.00"
        };
      }
    }
  },
  data() {
    return {};
  },
  components: { FormDatepickerBase },
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalReturningDoc: {
      get: function() {
        return this.globalReturningDoc;
      },
      set: function(newValue) {
        this.$emit("update:globalReturningDoc", newValue);
      }
    }
  },
  watch: {},
  methods: {}
};
</script>
