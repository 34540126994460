<template>
  <v-stepper v-model="actionParams.wizardStep">
    <v-stepper-header>
      <v-stepper-step :complete="actionParams.wizardStep > 1" step="1">
        {{ $t("STEP") }} 1
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 2" step="2">
        {{ $t("STEP") }} 2
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 3" step="3">
        {{ $t("STEP") }} 3
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <StepStockActionSelectStoreSupplierReturning
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :items.sync="items"
          :selectedStore.sync="selectedStore"
          :selectedSupplier.sync="selectedSupplier"
          @updateSupplierId="globalReturningDoc.supplierId = $event"
          @updateSupplierCurrencyId="globalReturningDoc.currencyId = $event"
          @updateSupplierVatGroupId="globalReturningDoc.vatGroupId = $event"
        >
        </StepStockActionSelectStoreSupplierReturning>
      </v-stepper-content>
      <v-stepper-content step="2">
        <StepStockActionSetItems
          :firstLoader.sync="tempFirstLoader"
          :wizardStep.sync="actionParams.wizardStep"
          :globalReturningDoc.sync="globalReturningDoc"
          :applyButton="true"
          :notes.sync="notes"
          :items.sync="items"
          :headers="headers"
          :selectedItems="selectedItems"
          :selectedItemsTotal="selectedItemsTotal"
          :store="selectedStore"
          :supplier="selectedSupplier"
          :actionReturning="true"
          :stockActionTypeId="stockActionTypeId"
          :showTotalPrice="false"
          :currencies="currencies"
          :vatGroups="vatGroups"
        >
        </StepStockActionSetItems>
      </v-stepper-content>
      <v-stepper-content step="3">
        <StepStockActionPreview
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :globalReturningDoc.sync="globalReturningDoc"
          :notes.sync="notes"
          :selectedItems="selectedItems"
          :headers="headers"
          :selectedItemsTotal="selectedItemsTotal"
          :selectedStore.sync="selectedStore"
          :supplier="selectedSupplier"
          :actionReturning="true"
          :stockActionTypeId="stockActionTypeId"
          :showTotalPrice="false"
          :currencies="currencies"
          :vatGroups="vatGroups"
        >
        </StepStockActionPreview>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import StepStockActionSelectStoreSupplierReturning from "@/modules/stock/components/actions/stockReturning/StepStockActionSelectStoreSupplierReturning.vue";
import StepStockActionSetItems from "@/modules/stock/components/actions/StepStockActionSetItems.vue";
import StepStockActionPreview from "@/modules/stock/components/actions/StepStockActionPreview.vue";
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import _ from "lodash";
export default {
  name: "StockReturningStepper",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    stockActionTypeId: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      doGetItems: true,
      actionParams: {
        wizardStep: 1
      },
      globalReturningDoc: {
        visible: true,
        num: "",
        date: "",
        vatGroupId: 1,
        currencyId: 1,
        supplierId: null,
        totalCurrency: "0.00",
        totalCurrencyReturning: "0.00"
      },
      notes: "",
      items: [],
      selectedStore: {},
      selectedSupplier: {},
      currencies: {},
      vatGroups: {},
      headers: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("QUANTITY"),
          value: "stockActionItem.sumLastStockActionsUnitValue",
          sortable: true
        },
        {
          text: this.$t("RETURNING"),
          value: "stockActionItem.unitValue",
          sortable: true
        },
        {
          text: this.$t("SINGLE_PRICE"),
          value: "stockActionItem.singlePrice",
          sortable: true
        },
        {
          text: this.$t("TOTAL_PRICE"),
          value: "stockActionItem.totalPrice",
          sortable: true
        },
        {
          text: this.$t("SINGLE_PRICE_RETURNING"),
          value: "returning.singlePrice",
          sortable: true
        },
        {
          text: this.$t("TOTAL_PRICE_RETURNING"),
          value: "returning.totalPrice",
          sortable: true
        },
        {
          text: this.$t("LOT"),
          value: "stockActionItem.stockLot",
          sortable: true
        },
        {
          text: this.$t("LOT_EXP_DATE"),
          value: "stockActionItem.stockLotExpDate",
          sortable: true
        }
      ]
    };
  },
  components: {
    StepStockActionSelectStoreSupplierReturning,
    StepStockActionSetItems,
    StepStockActionPreview
  },
  mounted() {
    let vm = this;
    vm.getSelects();
  },
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    selectedItems() {
      let vm = this;
      let selectedItems = [];
      if (_.size(vm.items) > 0) {
        vm.items.forEach(category => {
          let filteredItems = category.items.filter(function(item) {
            return item.stockActionItem.unitValue > 0;
          });
          selectedItems = [...selectedItems, ...filteredItems];
        });
      }
      return selectedItems;
    },
    selectedItemsTotal() {
      let vm = this;
      let totalPrice = 0;
      vm.selectedItems.forEach(item => {
        let itemTotalPrice = item.stockActionItem.totalPrice;
        // let transportPrice = item.stockActionItem.transportPrice;
        totalPrice += parseFloat(itemTotalPrice);
      });
      return this.$options.filters.formatBalance(totalPrice);
    }
  },
  watch: {
    selectedItems: {
      handler: function(newValue, oldValue) {
        this.globalReturningDoc.totalCurrency = newValue
          .reduce(
            (sum, item) => sum + parseFloat(item.stockActionItem.totalPrice),
            0
          )
          .toFixed(6);

        this.globalReturningDoc.totalCurrencyReturning = newValue
          .reduce((sum, item) => sum + parseFloat(item.returning.totalPrice), 0)
          .toFixed(6);
      },
      deep: true
    }
  },
  methods: {
    getSelects() {
      let vm = this;
      let payload = {
        forwarders: {
          fields: ["id", "name"],
          search: ""
        },
        contractors: {
          fields: ["id", "name"],
          search: ""
        },
        customs: {
          fields: ["id", "name"],
          search: ""
        },
        currencies: {
          fields: ["id", "name", "code"],
          search: ""
        },
        vat_groups: {
          fields: ["id", "name", "percent"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.forwarders = data.data.forwarders;
          vm.contractors = data.data.contractors;
          vm.customs = data.data.customs;
          vm.currencies = data.data.currencies;
          vm.vatGroups = data.data.vat_groups;
          vm.globalDoc.currencyId = vm.currencies[0].id;
          vm.globalDoc.vatGroupId = vm.vatGroups[0].id;
        })
        .catch(response => {
          console.log(response);
        });
    },
    updateWizardStep(step) {
      let vm = this;
      vm.actionParams.wizardStep = step;
    }
  }
};
</script>
