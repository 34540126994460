<template>
  <b-row>
    <template v-if="tempGlobalCustomsDoc.visible == false">
      <b-col>
        <v-btn color="blue darken-1" text @click="addService">
          <v-icon dark>mdi-plus</v-icon>
          {{ $t("ADD") }} {{ $t("CUSTOMS") }}
        </v-btn>
      </b-col>
    </template>
    <template v-if="tempGlobalCustomsDoc.visible == true">
      <b-col :sm="12">
        <v-btn color="red darken-1" text @click="removeService">
          <v-icon dark>mdi-minus</v-icon>
          {{ $t("REMOVE") }}
          {{ $t("CUSTOMS") }}
        </v-btn>
        <h3>{{ $t("CUSTOMS") }}:</h3>
      </b-col>
      <b-col lg="3">
        <v-autocomplete
          hide-details
          :items="customs"
          :label="$t('CUSTOMS_OFFICE')"
          item-text="name"
          item-value="id"
          clearable
          v-model="tempGlobalCustomsDoc.customsId"
        ></v-autocomplete>
      </b-col>
      <b-col>
        <v-text-field
          v-model="tempGlobalCustomsDoc.num"
          :label="$t('DOC_NUM')"
          hide-details
          type="number"
          step="1"
          min="0"
        ></v-text-field>
      </b-col>
      <b-col>
        <FormDatepickerBase
          :type="'text'"
          :i18n="{
            label: 'DOC_DATE',
            placeholder: $t('DOC_DATE')
          }"
          :date.sync="tempGlobalCustomsDoc.date"
        ></FormDatepickerBase>
      </b-col>
      <b-col>
        <v-text-field
          :disabled="true"
          v-model="tempGlobalCustomsDoc.totalCurrency"
          :label="$t('TOTAL_PRICE')"
          hide-details
          type="number"
          step="1"
          min="0"
        ></v-text-field>
      </b-col>
      <b-col lg="1">
        <v-select
          hide-details
          :items="currencies"
          item-text="code"
          item-value="id"
          :label="$t('CURRENCY')"
          disabled
          v-model="tempGlobalCustomsDoc.currencyId"
        ></v-select>
      </b-col>
      <b-col lg="1">
        <v-select
          hide-details
          :items="vatGroups"
          item-text="name"
          item-value="id"
          :label="$t('VAT_GROUPS')"
          disabled
          v-model="tempGlobalCustomsDoc.vatGroupId"
        ></v-select>
      </b-col>
    </template>
  </b-row>
</template>
<script>
import FormDatepickerBase from "@/view/content/forms/components/FormDatepickerBase.vue";
import _ from "lodash";
export default {
  name: "SetCustomsDoc",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    vatGroups: {
      type: Array,
      default: function() {
        return [];
      }
    },
    currencies: {
      type: Array,
      default: function() {
        return [];
      }
    },
    customs: {
      type: Array,
      default: function() {
        return [];
      }
    },
    globalCustomsDoc: {
      type: Object,
      default: function() {
        return {
          visible: false,
          num: "",
          date: "",
          customsId: 1,
          vatGroupId: 1,
          currencyId: 1,
          totalCurrency: "0.00"
        };
      }
    }
  },
  data() {
    return {};
  },
  components: { FormDatepickerBase },
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempGlobalCustomsDoc: {
      get: function() {
        return this.globalCustomsDoc;
      },
      set: function(newValue) {
        this.$emit("update:globalCustomsDoc", newValue);
      }
    }
  },
  watch: {},
  methods: {
    addService() {
      let vm = this;
      vm.tempGlobalCustomsDoc.visible = true;
    },
    removeService() {
      let vm = this;
      vm.tempGlobalCustomsDoc.visible = false;
    }
  }
};
</script>
